import { inject, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
}from '@angular/common/http';

import { Observable } from 'rxjs'

@Injectable()
export class HeaderInterceptor implements HttpInterceptor{
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const token = sessionStorage.getItem('jwt') 
        const headerDict = {
            'ChannelId': 'Y3dh',
            'ChannelSecret': '04686b37edfb4c2fa123f40677473b7d',
            'AppID':'Portal',
            'AppKey':'2xd4242425f656u6u'
          }

        request =  request.clone({
            headers: new HttpHeaders(headerDict), 
        });
          const requestOptions = {                                                                                                                                                                                 
            headers: new HttpHeaders(headerDict), 
          };
          
        return next.handle(request)
    }
}